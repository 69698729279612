import React, { useContext, useState } from 'react';
import Grid from "@material-ui/core/Grid";
import { Box, makeStyles } from "@material-ui/core";
import GeneralDataContext from "../wrappers/contexts/GeneralDataContext";
import sendEmail from "../utils/sendEmail";
import TextField from "@material-ui/core/TextField";
import GenericTranslatesContext from "../wrappers/contexts/GenericTranslatesContext";
import Button from "@material-ui/core/Button";
import RoomIcon from '@material-ui/icons/Room';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import PhoneIcon from '@material-ui/icons/Phone';
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import MoveHoverTurbo from "../shared/move/MoveHoverTurbo";
import ReCAPTCHA from "react-google-recaptcha";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import "./contact/contact.css"

const REACT_APP_GOOGLE_CAPTCHA_KEY = process.env.REACT_APP_GOOGLE_CAPTCHA_KEY;

const useStyles = makeStyles(theme => ({
  containerMap: {
    minHeight: "100vh"
  },
  itemText: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(10)
  },
  info: {
    padding: "5px 0 5px 35px"
  }
}));

export default ({ info = true, heightMap = "100vh", style = {} }) => {
  const classes = useStyles();
  const generalData = useContext(GeneralDataContext);
  return (
    <>
      <Grid container justify="center" className={classes.containerMap} style={{ paddingTop: "60px", ...style }}>
        {generalData.urlGoogleMapsEmbed && <Grid item xs={12} lg={7}>
          <div style={{ height: heightMap }} className="mapBox">
            <iframe title="Map Ynaira Hotel" src={generalData.urlGoogleMapsEmbed} width="100%" height="100%"
                    style={{ border: 0 }}
                    aria-hidden={false} tabIndex={0} frameBorder={0}/>
          </div>
        </Grid>}
        <Grid item xs={12} lg={5} className={classes.itemText}>
          <FormContact/>
          {info && <InfoContact/>}
        </Grid>
      </Grid>
    </>
  )
}


const InfoContact = () => {
  const generalData = useContext(GeneralDataContext);
  const classes = useStyles();
  return (
    <Grid container direction={"column"} style={{ paddingTop: "25px" }}>
      <Grid item className="margin-0" style={{ marginBottom: "20px" }}>
        <div style={{ position: "absolute", paddingTop: "3px" }}>
          <RoomIcon/>
        </div>
        <p className={classes.info}>
          {generalData.street}
        </p>
        <p className={classes.info}>
          {generalData.postCode} - {generalData.town}
        </p>
        <p className={classes.info}>
          {generalData.province}
        </p>
      </Grid>
      <Grid item className="margin-0" style={{ marginBottom: "20px", maxWidth: "100%" }}>
        <div style={{ position: "absolute", paddingTop: "3px" }}>
          <AlternateEmailIcon/>
        </div>
        <p className={classes.info} style={{ overflowWrap: "break-word" }}>
          {generalData.email}
        </p>
      </Grid>
      <Grid item className="margin-0" style={{ marginBottom: "20px" }}>
        <div style={{ position: "absolute", paddingTop: "3px" }}>
          <PhoneIcon/>
        </div>
        <p className={classes.info}>
          {generalData.labelPhone}
        </p>
      </Grid>
      <Grid item style={{ marginBottom: "20px", paddingLeft: "30px" }}>
        <Grid container>
          <IconsSocial/>
        </Grid>
      </Grid>
    </Grid>
  )
};

const IconsSocial = () => {
  const generalData = React.useContext(GeneralDataContext);
  const classes = useStyles();
  const social = [
    { href: generalData.facebookUrl, icon: FacebookIcon },
    { href: generalData.instagramUrl, icon: InstagramIcon }
  ];
  return (
    <React.Fragment>
      {social.map((net, key) => {
        const Icon = net.icon;
        return <MoveHoverTurbo key={key} changes={[{ style: "scale", init: 1, hover: 1.3 }]} speed={100}>
          <Box component="a" rel="noopener noreferrer" target="_blank" href={net.href}>
            <Icon fontSize={"large"} className={classes.icons}/>
          </Box>
        </MoveHoverTurbo>
      })}
    </React.Fragment>
  )
};

const FormContact = () => {

  const [info, setInfo] = useState({});
  const [captchaAccept, setCaptchaAccept] = useState(false);
  const [conditions, setCondition] = useState(false);
  const generalData = useContext(GeneralDataContext);
  const { getTranslate } = useContext(GenericTranslatesContext);

  const submit = () => {
    let correct = true;
    if (Object.keys(info).length <= 0) {
      correct = false;
    }
    Object.keys(info).forEach(key => {
      if (info[key] === "" || info[key] === null || !info[key]) {
        correct = false;
      }
    });

    if (correct) {
      sendEmail({
        title: "Comentario web Ynaira",
        html: `<h4>Comentario de parte de: ${info.name + " " + info.surname}</h4><p>Nombre: ${info.name + " " + info.surname}</p><p>Teléfono: ${info.phone}</p><p>Correo: ${info.email}</p><p>Comentario: ${info.comment}</p>`,
        from: "ynaira@kinacusports.com",
        to: (generalData.email)
      }).then(() => alert(getTranslate("Mensaje enviado correctamente")))
        .catch(() => alert("Error"))
    } else {
      alert(getTranslate("Todos los campos son obligatorios"))
    }
  };

  const change = (event, name) => setInfo({ ...info, [name]: event.target.value });

  return (
    <form autoComplete="off" onSubmit={submit}>
      <Grid container justify={"center"} style={{ paddingBottom: "40px", textTransform: "uppercase" }}>
        <h1>
          {getTranslate("Contáctanos")}
        </h1>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs>
          <TextField style={{ minWidth: "260px" }} label={getTranslate("Nombre")} value={info.name} fullWidth
                     onChange={(event) => change(event, "name")}
          />
        </Grid>
        <Grid item xs>
          <TextField style={{ minWidth: "260px" }} label={getTranslate("Apellido")} value={info.surname}
                     fullWidth
                     onChange={(event) => change(event, "surname")}
          />
        </Grid>
        <Grid item xs>
          <TextField style={{ minWidth: "260px" }} type="number" label={getTranslate("Teléfono")}
                     value={info.phone} fullWidth
                     onChange={(event) => change(event, "phone")}
          />
        </Grid>
        <Grid item xs>
          <TextField style={{ minWidth: "260px" }} type="email" label={getTranslate("Email")} value={info.email}
                     fullWidth
                     onChange={(event) => change(event, "email")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField style={{ minWidth: "260px" }} multiline rows="4" label={getTranslate("Comentario")}
                     value={info.comment} fullWidth
                     onChange={(event) => change(event, "comment")}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center">
            <ReCAPTCHA
              sitekey={REACT_APP_GOOGLE_CAPTCHA_KEY}
              onChange={() => setCaptchaAccept(true)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} container justify="center">
          <Button disabled={(!captchaAccept || !conditions)}
                  style={{ minWidth: "260px", marginTop: "10px" }}
                  variant="outlined"
                  color="primary"
                  onClick={submit}
          >
            {getTranslate("Enviar")}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Grid container justify="center">
            <FormControlLabel
              control={
                <Checkbox color={"primary"} checked={conditions} onChange={() => setCondition(!conditions)}/>
              }
              label={getTranslate("Sí, acepto las condiciones de privacidad")}
            />
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
};

