import React, { useContext } from 'react';
import { Box, makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import constants from "../../../styles/constants";
import GeneralDataContext from "../../wrappers/contexts/GeneralDataContext";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import MoveHoverTurbo from "../move/MoveHoverTurbo";
import RoomIcon from "@material-ui/icons/Room";
import AlternateEmailIcon from "@material-ui/icons/AlternateEmail";
import PhoneIcon from "@material-ui/icons/Phone";
import GenericTranslatesContext from "../../wrappers/contexts/GenericTranslatesContext";
import { useHistory } from "react-router"
import { NavLink } from "react-router-dom";
import getCorrectTranslate from "../../utils/getCorrectTranslate";
import AssignmentLateOutlinedIcon from '@material-ui/icons/AssignmentLateOutlined';
import FingerprintOutlinedIcon from '@material-ui/icons/FingerprintOutlined';
import cookies from "../../../media/icons/cookiesFill.svg"

const useStyles = makeStyles(theme => ({
  container: {
    // paddingTop: "20px",
    paddingBottom: "10px",
    [theme.breakpoints.down('sm')]: {
      paddingBottom: "70px",
    },
  },
  section: {
    padding: "15px 25px",
    [theme.breakpoints.down("xs")]: {
      paddingRight: "5px",
      paddingLeft: "5px",
    }
  },
  sectionTitle: {
    width: "100%",
    paddingBottom: "5px",
    borderBottom: "1px solid " + constants.color.whiteOp(0.5),
    "&:hover": {
      borderBottom: "1px solid " + constants.color.whiteOp(1),
    }
  },
  info: {
    padding: "5px 0 5px 35px"
  }
}));


export default () => {
  const classes = useStyles();
  const generalData = useContext(GeneralDataContext);
  const history = useHistory();

  const iconsLegal = [<AssignmentLateOutlinedIcon/>, <FingerprintOutlinedIcon/>, <img style={{marginLeft: "2px", marginTop: "3px"}} src={cookies} width="19px" height="19px" alt=""/>];

  return (
    <Box component="footer">
      <Container maxWidth={"xl"}>
        <Grid container direction={"row"} justify="center" className={classes.container + " textWhite"}>
          <SectionFooter title={"Dirección"} onClick={() => {
            const win = window.open(generalData.googleMapsUrl, '_blank');
            win.focus();
          }}>
            <div style={{ position: "absolute" }}>
              <RoomIcon/>
            </div>
            <p className={classes.info}>
              {generalData.street}
            </p>
            <p className={classes.info}>
              {generalData.postCode} - {generalData.town}
            </p>
          </SectionFooter>
          <SectionFooter title={"Contacto"} onClick={() => history.push("contact")}>
            <div style={{ position: "absolute" }}>
              <AlternateEmailIcon/>
            </div>
            <p className={classes.info} style={{ overflowWrap: "break-word" }}>
              {generalData.email}
            </p>
            <div style={{ position: "absolute" }}>
              <PhoneIcon/>
            </div>
            <p className={classes.info}>
              {generalData.labelPhone}
            </p>
          </SectionFooter>
          <SectionFooter title={"Legal"}>
            {generalData.legal && generalData.legal.map((legal, key) => {
              return <React.Fragment key={key}>
                <div style={{ position: "absolute" }}>
                  {iconsLegal[key]}
                </div>
                <p className={classes.info} style={{ overflowWrap: "break-word" }}>
                  <NavLink to={legal.url}>
                    {getCorrectTranslate(legal.title)}
                  </NavLink>
                </p>
              </React.Fragment>
            })}
          </SectionFooter>
          <SectionFooter title={"Síguenos"}>
            <Grid container justify="flex-start">
              <IconsSocial/>
            </Grid>
          </SectionFooter>
        </Grid>
      </Container>
    </Box>
  )
};

const SectionFooter = ({ title, children, onClick }) => {
  const classes = useStyles();
  const { getTranslate } = useContext(GenericTranslatesContext);
  return (
    <Grid item xs={12} sm={6} lg={3} className={classes.section} onClick={onClick}
          style={{ cursor: (onClick ? "pointer" : "") }}>
      <div className={classes.sectionTitle}>
        <h4>
          {getTranslate(title)}
        </h4>
      </div>
      <div style={{ marginTop: "35px" }} className="margin-5">
        {children}
      </div>
    </Grid>
  )
};

const IconsSocial = () => {
  const generalData = React.useContext(GeneralDataContext);
  const classes = useStyles();
  const social = [
    { href: generalData.facebookUrl, icon: FacebookIcon },
    { href: generalData.instagramUrl, icon: InstagramIcon }
  ];
  return (
    <React.Fragment>
      {social.map((net, key) => {
        const Icon = net.icon;
        return <MoveHoverTurbo key={key} changes={[{ style: "scale", init: 1, hover: 1.3 }]} speed={100}>
          <Box component="a" rel="noopener noreferrer" target="_blank" href={net.href}>
            <Icon fontSize={"large"} className={classes.icons}/>
          </Box>
        </MoveHoverTurbo>
      })}
    </React.Fragment>
  )
};
