import React from "react"
import { Container, makeStyles } from "@material-ui/core"
import Box from "@material-ui/core/Box"
import cacheImage from "../utils/cacheImage"
import Grid from "@material-ui/core/Grid"
import getCorrectTranslate from "../utils/getCorrectTranslate"

const REACT_APP_API_URL = process.env.REACT_APP_API_URL


const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    marginBottom: 0,
    display: "flex",
    justifyContent: "center",
  },
  cardWrapper: {
    zIndex: 1,
  },
  card: {
    display: "flex",
    justifyContent: "center",
    // color: constants.color.background,
    flexWrap: "wrap",
    flexDirection: "column",
    backgroundColor: "#e0d0c3",
    padding: theme.spacing(3, 3),

  },
  cardContent: {
    maxWidth: 400,
  },
  textField: {
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  button: {
    width: "100%",
  },
  imagesWrapper: {
    position: "relative",
  },
  imageDots: {
    position: "absolute",
    top: -67,
    left: -67,
    right: 0,
    bottom: 0,
    width: "100%",
    background: "url(/static/onepirate/productCTAImageDots.png)",
  },
  image: {
    position: "absolute",
    top: -28,
    left: -28,
    right: 0,
    bottom: 0,
    width: "100%",
    maxWidth: "600px",
  },
  imageNormal: {
    width: "100%",
  },
}))


export default ({ component }) => {
  const classes = useStyles()
  const { textAlign, link, text } = component
  const photo = cacheImage((REACT_APP_API_URL + component.photo.url))
  const definitiveText = getCorrectTranslate(text)

  if (!definitiveText) {
    return (
      <Container maxWidth={false} className={classes.root} component="section">
        <Box style={{ width: "100%" }} component={link ? "a" : "div"}
             href={link || ""}>
          <Grid container>
            <Grid item xs={12} className={classes.imagesWrapper}>
              <img
                src={photo}
                alt="call to action"
                className={classes.imageNormal}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    )
  }

  return (
    <Container maxWidth={false} className={classes.root} component="section">
      <Box style={{ width: "100%" }} component={link ? "a" : "div"}
           href={link || ""}>
        <Grid container>
          <Grid item xs={12} className={classes.cardWrapper}>
            <div className={classes.card + " titleMargin-0"} style={{ textAlign }}>
              {definitiveText}
            </div>
          </Grid>
          <Grid item xs={12} className={classes.imagesWrapper}>
            <img
              src={photo}
              alt="call to action"
              className={classes.imageNormal}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  )
};


