import React, { useState } from 'react';
import ModalContext from "./contexts/ModalContext";
import Modal from "../shared/modal/Modal";


export default ({ children }) => {
  const [content, setContent] = useState(null);

  return (
    <ModalContext.Provider value={{ setContent }}>
      <Modal changeModal={() => setContent(null)} children={content}/>
      {children}
    </ModalContext.Provider>
  )
};
