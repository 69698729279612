import React, { useContext, useState } from 'react';
import posed from "react-pose";
import { makeStyles } from "@material-ui/core";
import constants from "../../../styles/constants";
import Grid from "@material-ui/core/Grid";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MoveHoverTurbo from "../../shared/move/MoveHoverTurbo";
import getCorrectTranslate from "../../utils/getCorrectTranslate";
import GenericTranslatesContext from "../../wrappers/contexts/GenericTranslatesContext";
import Container from "@material-ui/core/Container";
import getFeatureIcon from "../../utils/getFeatureIcon";

const Menu = posed.div({
  open: {
    height: "100%",
  },
  close: {
    height: 0,
  }
});

const useStyles = makeStyles({
  container: {
    backgroundColor: constants.color.primaryOp(0.2),
    overflow: "hidden"
  },
  opener: {
    backgroundColor: constants.color.primaryOp(0.9),
    cursor: "pointer"
  }
});

export default ({ features }) => {
  const [open, setOpen] = useState(true);
  const { getTranslate } = useContext(GenericTranslatesContext);
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid container className={classes.opener} onClick={() => setOpen(!open)}>
        <Container maxWidth={"xl"}>
          <Grid container className={"textWhite"} alignItems={"center"}
                onClick={() => setOpen(!open)}>
            <MoveHoverTurbo changes={[{ style: "scale", init: 1, hover: 1.2 }]} speed={100}>
              <ArrowDropDownIcon fontSize="large"/>
            </MoveHoverTurbo>
            <h6 style={{textTransform: "uppercase"}}>
              {getTranslate("Características")}
            </h6>
          </Grid>
        </Container>
      </Grid>
      <Menu pose={open ? "open" : "close"} className={classes.container}>
        <Container maxWidth={"lg"} style={{ padding: "10px" }}>
          <Grid container spacing={1}>
            {features.map((feat, key) => <Grid key={key} item xs={12} sm={6}>
              <Grid container direction="row" alignItems="center" style={{ fontSize: "20px", padding: "5px" }}>
                {getFeatureIcon(feat.icon)}
                <div style={{ padding: "0 5px" }}>
                  {getCorrectTranslate(feat.text.translate)}
                </div>
              </Grid>
            </Grid>)}
          </Grid>
        </Container>
      </Menu>
    </React.Fragment>
  )
}