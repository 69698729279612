import React, { useContext, useState } from 'react';
import posed from "react-pose";
import { Button, makeStyles } from "@material-ui/core";
import constants from "../../../styles/constants";
import ContactContext from "../../wrappers/contexts/GeneralDataContext";
import getCorrectTranslate from "../../utils/getCorrectTranslate";
import GenericTranslatesContext from "../../wrappers/contexts/GenericTranslatesContext";

const TheBox = posed.div(() => {
  return ({
    visible: {
      height: "auto",
      applyAtStart: { display: 'block' },
      transition: { duration: 700 }
    },
    invisible: {
      height: 0,
      applyAtEnd: { display: 'none' },
      transition: { duration: 700 }
    },
  })
});

const useStyles = makeStyles(() => ({
  box: {
    backgroundColor: constants.color.white,
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
    position: "fixed",
    maxWidth: "350px",
    width: "95%",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 10000,
    padding: "0px 25px",
    overflow: "hidden"
  }
}));

export default () => {
  const contact = React.useContext(ContactContext);
  const [accepted, setAccepted] = useState(localStorage.getItem("cookies-Ynaira"));
  const classes = useStyles();
  const { getTranslate } = useContext(GenericTranslatesContext);

  const accept = () => {
    localStorage.setItem("cookies-Ynaira", true);
    setAccepted(true)
  };

  return (
    <TheBox pose={!accepted ? "visible" : "invisible"} className={classes.box + " margin-5 cookies"}
            style={{ textAlign: contact.cookie ? contact.cookie.alignament : "center" }}>
      <div style={{ padding: "25px 0px" }}>
        {contact.cookie && getCorrectTranslate(contact.cookie.translate)}
        <Button id="exception" color={"primary"} style={{ borderColor: "#8f7954", minWidth: "150px" }}
                className="textWhite" variant="contained" fullWidth onClick={accept}>
          <div>
            {getTranslate("Aceptar")}
          </div>
        </Button>
      </div>
    </TheBox>
  )
};
