import React from 'react';
import { Container, makeStyles } from "@material-ui/core";
import BannerImg from "./shared/banner/BannerImg";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import getWindowSize from "../utils/getWindowSize";
import MoveHoverTurbo from "../shared/move/MoveHoverTurbo";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import cacheImage from "../utils/cacheImage";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const useStyles = makeStyles({
  arrows: {
    marginRight: "20px",
    marginLeft: "20px",
    cursor: "pointer"
  },
  button: {
    backgroundColor: "transparent",
    border: "none",
  },
  back: {
    left: 20
  },
  next: {
    right: 20
  },
  menu: {
    position: "absolute",
    zIndex: 1000,
    width: "100%",
    // marginTop: "30px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 10px",
    left: 0
  },
  menuColor0: {
    padding: "5px 25px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  try: {
    maxHeight: "500px"
  }
});

export default ({ component }) => {
  const photos = component.photos;

  if (photos.length <= 0) {
    return null
  }
  return <Container maxWidth={false}>
    <TheSlider photos={photos} component={component}/>
  </Container>
};

export const TheSlider = ({ component, photos, numbImg }) => {

  const windowSize = getWindowSize();
  const classes = useStyles();

  return (
    <CarouselProvider
      style={{ maxHeight: "500px" }}
      naturalSlideWidth={100}
      naturalSlideHeight={80}
      totalSlides={photos.length}
      interval={10000}
      isPlaying={true}
      lockOnWindowScroll={false}
      visibleSlides={numbImg ? numbImg : getNumImg(windowSize.width, photos.length)}
    >
      <div style={{ height: "inherit", width: "100%", display: "flex", alignItems: "center" }}>
        {(component.control) &&
        <div className={classes.menu}>
          <div className={classes.menuColor0}>
            <ButtonBack className={classes.button}>
              {(photos.length > 1 && component.control) &&
              <MoveHoverTurbo changes={[{ style: "scale", init: 1, hover: 1.2 }]} className="textWhite"
                              speed={100}>
                <NavigateBeforeIcon color={"inherit"} fontSize={"large"}/>
              </MoveHoverTurbo>
              }
            </ButtonBack>
            <ButtonNext className={classes.button}>
              {(photos.length > 1 && component.control) &&
              <MoveHoverTurbo changes={[{ style: "scale", init: 1, hover: 1.2 }]} className="textWhite"
                              speed={100}>
                <NavigateNextIcon color={"inherit"} fontSize={"large"}/>
              </MoveHoverTurbo>
              }
            </ButtonNext>
          </div>
        </div>
        }
        <div style={{ height: "100%", width: "100%" }}>
          <Slider style={{ maxHeight: "500px" }} classNameTray={classes.try} classNameTrayWrap={classes.try}>
            {photos.map((photo, key) =>
              <Slide key={key} index={key} className={classes.try} innerClassName={classes.try}>
                <BannerImg img={cacheImage((REACT_APP_API_URL + photo.url))} height={"100%"} parallax={false}
                           style={{ margin: "0 10px" }}/>
              </Slide>)
            }
          </Slider>
        </div>
      </div>
    </CarouselProvider>
  )
};

const getNumImg = (width, lenght) => {
  if (width > 1600) {
    if (lenght >= 4) {
      return 4
    }
  }
  if (width > 1300) {
    if (lenght >= 3) {
      return 3
    }
  }
  if (width > 600) {
    if (lenght >= 2) {
      return 2
    }
  }
  return 1
};
