import React, { useState, useEffect, useContext } from 'react';
import ContactContext from "./contexts/GeneralDataContext";
import { getGeneralData } from "../../api/apiRequest";
import LoadingControlContext from "./contexts/LoadingControlContext";


export default ({ children }) => {
  const { setError } = useContext(LoadingControlContext);
  const generalData = localStorage.getItem("ynaira-general-data") ? JSON.parse(localStorage.getItem("ynaira-general-data")) : {};
  const [data, setData] = useState(generalData);

  const error = () => setError("No se han encontrado datos generales");
  useEffect(() => {
    getGeneralData().then(({ data }) => {
      if(data[0]){
        setData(data[0])
        localStorage.setItem("ynaira-general-data", JSON.stringify(data[0]))
      } else {
        error()
      }
    }).catch(() => error())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <ContactContext.Provider value={data} children={children}/>
};
