import React, { useContext, useEffect, memo } from 'react';
import { Box, CssBaseline, ThemeProvider } from '@material-ui/core';
import globalTheme from './styles/globalTheme';
import { Redirect, Route, Switch, useLocation } from "react-router";
import { BrowserRouter } from "react-router-dom";
import LanguagesWrapper from "./components/wrappers/LanguagesWrapper";
import Nav from "./components/shared/nav/Nav"
import GenericTranslatesWrapper from "./components/wrappers/GenericTranslationsWrapper";
import GeneralDataWrapper from "./components/wrappers/GeneralDataWrapper";
import Footer from "./components/shared/footer/Footer";
import './App.css';
import BottomNav from "./components/shared/nav/BottomNav";
import Room from "./components/pages/Room";
import SectionsWrapper from "./components/wrappers/SectionsWrapper";
import SectionsContext from "./components/wrappers/contexts/SectionsContext";
import Section from "./components/pages/Section";
import Contact from "./components/pages/Contact";
import Cookies from "./components/shared/cookies/Cookies";
import LoadingControlWrapper from "./components/wrappers/LoadingControlWrapper";
import LanguagesContext from "./components/wrappers/contexts/LanguagesContext";
import LoadingControlContext from "./components/wrappers/contexts/LoadingControlContext";

export default () => {

  const MemoLanguagesWrapper = memo(LanguagesWrapper);
  return (
    <ThemeProvider theme={globalTheme}>
      <CssBaseline/>
      <LoadingControlWrapper>
        <MemoLanguagesWrapper>
          <GeneralDataWrapper>
            <GenericTranslatesWrapper>
              <SectionBlock/>
            </GenericTranslatesWrapper>
          </GeneralDataWrapper>
        </MemoLanguagesWrapper>
      </LoadingControlWrapper>
    </ThemeProvider>
  );
}

const SectionBlock = () => {
  const { lang } = useContext(LanguagesContext);
  return <BrowserRouter basename={lang}>
    <SectionsWrapper>
      <Router/>
    </SectionsWrapper>
  </BrowserRouter>
};

const Router = () => {
  const { sections } = useContext(SectionsContext);
  const { setLoading } = useContext(LoadingControlContext);
  setLoading(false);
  return (
    <>
      <ScrollToTop/>
      <Nav/>
      <Box style={{ minHeight: "100vh" }}>
        <Switch>
          <Route exact path="/room/:id" component={Room}/>
          <Route exact path="/contact" component={Contact}/>
          {sections.map((section, key) => <Route key={key} exact path={section.urlName} component={Section}/>)}
          <Redirect to="/"/>
        </Switch>
      </Box>
      <Footer/>
      <BottomNav/>
      <Cookies/>
    </>
  )
};

const ScrollToTop = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
};
