import React, { useState, useEffect, useContext } from 'react';
import LanguagesContext from "./contexts/LanguagesContext";
import { getLanguages } from "../../api/apiRequest";
import LoadingControlContext from "./contexts/LoadingControlContext";


export default ({ children }) => {
  const { setError } = useContext(LoadingControlContext);
  const [languages, setLanguages] = useState([]);
  const [lang, setLang] = useState(null);

  useEffect(() => {
    getLanguages().then(({ data }) => addLanguages(data)).catch(() => setError(true))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addLanguages = (languages) => {
    if (languages.length > 0) {
      setLanguages(languages);
      setLang(obtainLang(languages));
    } else {
      setError("No se han encontrado idiomas disponibles")
    }
  };

  const changeLang = (newLang) => {
    localStorage.setItem("locale-Ynaira", newLang);
    const actualPath = window.location.pathname;
    const existsLangOnPath = actualPath.indexOf(("/" + lang + "/")) >= 0;
    if (existsLangOnPath) {
      const pathReplaced = (actualPath).replace(("/" + lang + "/"), ("/" + newLang + "/"));
      window.location.pathname = pathReplaced;
    } else {
      window.location.pathname = ("/" + newLang + "/");
    }
  };

  if (!lang) {
    return null
  }

  return <LanguagesContext.Provider value={{ languages, lang, changeLang }} children={children}/>
};


const obtainLang = (languages) => {
  const pathname = window.location.pathname;
  const urlLang = pathname.split("/")[1];
  const coincidence = languages.filter(lang => lang.lang === urlLang)[0];
  return coincidence ? coincidence.lang : getLangInStorage(languages);
};

const getLangInStorage = (languages) => {
  const localSLang = localStorage.getItem("locale-Ynaira");
  const coincidence = languages.filter(lang => lang.lang === localSLang)[0];
  return coincidence ? coincidence.lang : filterNavigatorLang(languages);
};


const filterNavigatorLang = (languages) => {
  const navigatorLanguage = navigator.language;
  if (navigatorLanguage) {
    const prototype = navigatorLanguage.split("-")[0];
    const coincidence = languages.filter(lang => lang.lang === prototype)[0];
    return coincidence ? coincidence.lang : languages[0].lang;
  }
  return languages[0].lang
};

