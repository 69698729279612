import React from 'react';
import Grid from "@material-ui/core/Grid";
import getCorrectTranslate from "../../utils/getCorrectTranslate";
import { makeStyles } from "@material-ui/core";
import constants from "../../../styles/constants";

const useStyles = makeStyles((theme) => ({
  boxTextWhite: {
    backgroundColor: constants.color.background,
    opacity: "0.9",
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflow: "hidden",
    maxWidth: "500px"
  },
  boxception: {
    padding: theme.spacing(4),
    border: "1px solid " + constants.color.primaryOp(0.7)
  },
  boxceptionLight: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4)
  }
}));

export default ({ options, content, style, jump = true, ...props }) => {
  const { alignItems, justifyContent, height } = options;
  return (
    <Grid container {...props} direction="row" justify={translateflex(justifyContent)}
          alignItems={translateflex(alignItems)} style={{ height: "inherit", minHeight: "inherit", ...style }}>

      <Box options={options}>
        {getCorrectTranslate(content)}
      </Box>
      {jump && <div style={{height}}>

      </div>}
    </Grid>
  )
}

const Box = ({ children, options }) => {
  const classes = useStyles();
  const { textAlign, height = "", border, titleContrast } = options;
  return (
    <div className={classes.boxTextWhite} style={{ minHeight: height }}>
      <Grid container className={(border ? classes.boxception : classes.boxceptionLight)}
            style={{ textAlign, minHeight: height }} justify="center" alignItems="center">
        <div style={{ textAlign }} className={(titleContrast ? "titleContrast" : "") + " titleMargin-0"}>
          {children}
        </div>
      </Grid>
    </div>
  )
};

const translateflex = (text) => {
  switch (text) {
    case "start":
      return "flex-start";
    case "center":
      return "center";
    case "end":
      return "flex-end";
    default:
      return "center"
  }
};