import React, { useContext } from 'react';
import { Box, makeStyles } from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import RoomServiceIcon from "@material-ui/icons/RoomService";
import Hidden from "@material-ui/core/Hidden";
import GenericTranslatesContext from "../../wrappers/contexts/GenericTranslatesContext";
import GeneralDataContext from "../../wrappers/contexts/GeneralDataContext";
import constants from "../../../styles/constants";
import posed from "react-pose";

const Button = posed.div({
  hoverable: true,
  init: {
    bottom: 0,
  },
  hover: {
    bottom: 5,
  },
});

const useStyles = makeStyles(() => ({
  appBar: {
    top: 'auto',
    position: "fixed",
    right: 0,
    left: 0,
    zIndex: 100,
    backgroundColor: constants.color.extremColor,
  },
  icons: {
    height: "25px",
    width: "25px",
    color: constants.color.white,
    marginRight: "10px"
  },
  toolbar: {
    display: "flex",
    justifyContent: "center",
  },
  text: {
    color: constants.color.white,
    textDecoration: "none",
    textTransform: "uppercase",
    display: "flex",
    alignItems: "center"
  }
}));

export default () => {
  const { getTranslate } = useContext(GenericTranslatesContext);
  const contact = useContext(GeneralDataContext);
  const classes = useStyles();
  return (
    <Hidden mdUp>
        <Button position="fixed" className={classes.appBar}>
          {/* eslint-disable-next-line react/jsx-no-target-blank */}
          <a rel="noopener noreferrer" target="_blank" href={contact.bookingUrl}>
            <Toolbar className={classes.toolbar}>
              <Box className={classes.text}>
                <RoomServiceIcon className={classes.icons}/>
                <h5>
                  <strong>
                    {getTranslate("Reservar")}
                  </strong>
                </h5>
              </Box>
            </Toolbar>
          </a>
        </Button>
    </Hidden>
  )
};
