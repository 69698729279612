import React, { useContext } from 'react';
import { Container, Grid, makeStyles } from "@material-ui/core";
import BannerImg from "./shared/banner/BannerImg";
import constants from "../../styles/constants";
import getCorrectTranslate from "../utils/getCorrectTranslate";
import MoveHoverTurbo from "../shared/move/MoveHoverTurbo";
import ModalContext from "../wrappers/contexts/ModalContext";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const useStyles = makeStyles((theme) => ({
  item: {
    padding: "2px",
    textAlign: "center"
  },
  content: {
    cursor: "pointer",
    backgroundColor: constants.color.primaryOp(0.1),
    height: "100%"
  },
  containerText: {
    padding: "10px"
  },
  imgModal: {
    display: "block",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  }
}));

export default ({ items }) => {
  const classes = useStyles();
  const modal = useContext(ModalContext);

  const changeModal = (item) => {
    modal.setContent(<ModalItem item={item}/>)
  };

  return (
    <Container component="section" maxWidth={false}>
      <Grid container justify="center">
        {items.map((item, key) => <Grid key={key} item xs={12} sm={6} md={4} lg={3} className={classes.item}>
          <MoveHoverTurbo changes={[{ style: "y", init: 0, hover: -5 }]} className={classes.content} onClick={() => item.textModal.length > 0 ? changeModal(item) : null}>
              <BannerImg parallax={false} img={REACT_APP_API_URL + item.photo.url} height={"300px"}/>
              <div
                className={(getCorrectTranslate(item.text) ? classes.containerText : "") + " margin-5 titleContrast"}>
                {getCorrectTranslate(item.text)}
              </div>
          </MoveHoverTurbo>
        </Grid>)}
      </Grid>
    </Container>
  )
}

const ModalItem = ({item}) => {
  const classes = useStyles();
  return <Grid container alignItems="center">
    <Grid item xs={6} className={classes.imgModal}>
      <BannerImg parallax={false} img={REACT_APP_API_URL + item.photo.url} height={"450px"}/>
    </Grid>
    <Grid item xs={12} md={6} style={{padding: "15px"}} className="titleMargin-0 titleContrast linkContrast">
      {getCorrectTranslate(item.textModal)}
    </Grid>
  </Grid>
};
