import ReactMarkdown from "react-markdown";
import React, { useContext } from "react";
import LanguagesContext from "../wrappers/contexts/LanguagesContext";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export default (translates, lang = useContext(LanguagesContext).lang) => {
  if (translates) {
    const correctTranslate = translates.filter(trans => (trans.language ? trans.language.lang : trans.lang) === lang)[0];
    if (correctTranslate && correctTranslate.text) {
      return correctTranslate.text
    } else if (correctTranslate && correctTranslate.content) {
      return <ReactMarkdown source={correctTranslate.content} transformImageUri={(path) => REACT_APP_API_URL + path}/>
    }
  }
  return null;
}
