import React, { useState, useEffect, useContext } from "react"
import SectionsContext from "./contexts/SectionsContext"
import { getSectionContent, getSectionsNoContent } from "../../api/apiRequest"
import LoadingControlContext from "./contexts/LoadingControlContext"
import { useLocation } from "react-router"

export default ({ children }) => {
  const { setError } = useContext(LoadingControlContext)
  const { pathname } = useLocation()
  const prevNoData = localStorage.getItem("ynaira-section-data-noContent") ? JSON.parse(localStorage.getItem("ynaira-section-data-noContent")) : null
  const prevData = localStorage.getItem("ynaira-section-data-content") ? JSON.parse(localStorage.getItem("ynaira-section-data-content")) : {}
  const [sections, setSections] = useState(prevNoData)
  const [content, setContent] = useState(prevData)

  useEffect(() => {
    getSectionsNoContent()
      .then(({ data }) => {
        setSections(data)
        localStorage.setItem("ynaira-section-data-noContent", JSON.stringify(data))
      })
      .catch(() => setError("No se ha podido encontrar las secciones"))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (sections) {
      const sectionToComplete = sections.filter(section => section.urlText === pathname)[0]
      if (sectionToComplete) {
        // if (!content[sectionToComplete.id]) {
          getSectionContent(sectionToComplete.id)
            .then(({ data }) => {
              const newContent = { ...content, [sectionToComplete.id]: data }
              localStorage.setItem("ynaira-section-data-content", JSON.stringify(newContent))
              setContent(newContent)
            })
            .catch(() => setError("No se ha podido encontrar el contenido de las secciones"))
        // }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sections, pathname])

  if (!sections) {
    return null
  }

  return (
    <SectionsContext.Provider value={{ sections, content }} children={children}/>
  )
};
